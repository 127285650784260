/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('Magento_Catalog/js/product/view/product-ids',[
    'ko'
], function (ko) {
    'use strict';

    return ko.observableArray([]);
});

